<template>
    <banner-main @open-popup="openPopup" />
    <main class="main">
        <catalog />
        <author-dishes />
        <booking @open-popup="openPopup" />
        <culture />
        <photos />
        <restaurants @open-popup="openPopup" />
        <discounts :token="token" @popup-login="popupLogin = true" />
        <reviews @open-popup-feedback="openPopupFeedback" />
        <location />
    </main>
    <feedback-popup
        v-if="isPopupFeedback"
        @click="isPopupFeedback = false"
        @close-popup-feedback="closePopupFeedback"
    />
    <reserv-popup v-if="isPopup" @click="(isPopup = false), (isModalVisible = false)" @close-popup="closePopup" />
    <login-popup v-if="popupLogin" @click="popupLogin = !popupLogin" @auth="userAuth" @close="closeLogin" />
</template>

<script>
import BannerMain from "@/components/BannerMain.vue";
import Catalog from "@/components/main/Catalog.vue";
import Location from "@/components/main/Location.vue";
import Booking from "@/components/main/Booking.vue";
import Culture from "@/components/main/Culture.vue";
import Restaurants from "@/components/main/Restaurants.vue";
import Discounts from "@/components/main/Discounts.vue";
import Reviews from "@/components/main/Reviews.vue";
import FeedbackPopup from "@/components/popups/FeedbackPopup.vue";
import ReservPopup from "@/components/popups/ReservPopup.vue";
import LoginPopup from "@/components/popups/LoginPopup.vue";
import axios from "axios";
import Photos from "@/components/main/Photos.vue";
import AuthorDishes from "@/components/main/AuthorDishes.vue";
export default {
    components: {
        BannerMain,
        Catalog,
        Location,
        Booking,
        Culture,
        Restaurants,
        Discounts,
        Reviews,
        FeedbackPopup,
        ReservPopup,
        LoginPopup,
        Photos,
        AuthorDishes,
    },
    name: "MainPage",
    data() {
        return {
            isPopupFeedback: false,
            isPopup: false,
            selectShop: "",
            popupLogin: false,
            token: localStorage.getItem("token"),
            isModalVisible: false,
        };
    },
    emits: {
        authData: null,
    },
    methods: {
        openPopup() {
            this.isPopup = true;
            this.isModalVisible = true;
        },
        closePopup() {
            this.isPopup = false;
            this.isModalVisible = false;
        },
        openPopupFeedback() {
            this.isPopupFeedback = true;
        },
        closePopupFeedback() {
            this.isPopupFeedback = false;
        },
        closeLogin() {
            this.popupLogin = false;
        },
        async userAuth(token) {
            try {
                const response = await axios.get("https://admin.tcode.online/api/projects/tatar/profile/", {
                    headers: {
                        Authorization: "Token " + token,
                    },
                });
                console.log(response.data);
                localStorage.setItem("token", token);
                localStorage.setItem("username", response.data.username);
                localStorage.setItem("name", response.data.first_name);
                this.$emit("authData", response.data);
                this.popupLogin = false;
            } catch (err) {
                // Если есть ошибка
                alert(err);
            }
        },
    },
    watch: {
        isModalVisible: function () {
            if (this.isModalVisible) {
                document.documentElement.classList.add("hidden");
            } else {
                document.documentElement.classList.remove("hidden");
            }
        },
    },
};
</script>
