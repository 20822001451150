<template>
    <div class="popupForm" @click="reloadPage">
        <div class="popupForm__container" @click.stop>
            <div class="popupForm__back" @click="backBook" v-if="isInputs">
                <img src="@/assets/img/svg/arrow_back.svg" alt="close" />
            </div>
            <div class="popupForm__close" @click="$emit('closePopup'), reloadPage">
                <img src="@/assets/img/svg/close_popup.svg" alt="close" />
            </div>
            <div class="popupForm__body">
                <h1 class="popupForm__title">{{ title }}</h1>
                <form class="form" id="form">
                    <div class="popupForm-select" v-if="isSelects">
                        <h2>Ресторан</h2>
                        <input type="hidden" required name="shop" id="shop" v-model="selectShop" />
                        <div id="selectedRest" :style="selectShop ? 'background: #f3f3f3' : ''">
                            <div class="block-flex">
                                <div
                                    class="currentRest"
                                    :style="selectStreet === 'Выберите ресторан' ? 'color:#B0B0B0' : 'color:#2A374B'"
                                >
                                    {{ selectStreet }} <span>{{ selectLocation }}</span>
                                </div>
                                <img :src="require('@/assets/img/svg/select-arrow.svg')" alt="arrow" class="arrow" />
                            </div>
                            <div class="listRest">
                                <div
                                    class="nameRest"
                                    :value="option.value"
                                    v-for="option in options"
                                    :key="option.id"
                                    @click="
                                        (selectShop = option.value),
                                            (selectStreet = option.name),
                                            (selectLocation = option.place),
                                            (date = null);
                                        time = option.time;
                                    "
                                >
                                    {{ option.name }}
                                    <span>{{ option.place }}</span>
                                </div>
                            </div>
                        </div>
                        <!-- <select
              required
              name="shop"
              id="shop"
              v-model="selectShop"
              :style="selectShop ? '' : 'background: #fff'"
            >
              <option value="" disabled hidden>Выберите ресторан</option>
              <option
                :value="option.value"
                v-for="option in options"
                :key="option.id"
              >
                {{ option.name }}
                <span>{{ option.place }}</span>
              </option>
            </select> -->
                    </div>
                    <div class="selects" v-if="isSelects">
                        <div class="form__visit input-block">
                            <label for="date">Дата посещения</label>
                            <div class="form__flex">
                                <vue-date-picker
                                    class="marketing-dashboard__datepicker"
                                    v-model="date"
                                    :min-date="new Date()"
                                    :format="format"
                                    :disabled-dates="
                                        selectShop === 'marjani' ? disabledDatesMarjani : disabledDatesTatar
                                    "
                                    model-type="dd.MM.yyyy"
                                    locale="ru"
                                    type="date"
                                    cancelText=""
                                    selectText="Выбрать"
                                    placeholder="Выбрать дату"
                                    month-name-format="long"
                                    :enable-time-picker="false"
                                    disable-year-select
                                    auto-apply
                                    required
                                    id="date"
                                />
                                <select name="time" id="time" class="select-time" v-model="time">
                                    <!--<template v-if="date === '10.06.2024'">
                    <option
                      v-for="time in workTimes2"
                      :key="time"
                      :value="time"
                    >
                      {{ time }}
                    </option>
                  </template>
                  <template
                    v-else-if="
                      date === '11.06.2024' ||
                      date === '12.06.2024' ||
                      date === '13.06.2024' ||
                      date === '14.06.2024'
                    "
                  >
                    <option
                      v-for="time in workTimes3"
                      :key="time"
                      :value="time"
                    >
                      {{ time }}
                    </option>
                  </template>-->
                                    <option v-for="time in selectWorkTimes" :key="time" :value="time">
                                        {{ time }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form__select-table input-block">
                            <label>Количество человек</label>
                            <select name="person" id="person" class="select-person" v-model="table">
                                <option v-for="table in countPersons" :key="table" :value="table">
                                    {{ table }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="inputs" v-if="isInputs">
                        <div class="input-block">
                            <label for="first_name">Имя</label>
                            <input
                                type="text"
                                name="first_name"
                                placeholder="Введите имя"
                                id="name"
                                v-model.trim="first_name"
                                :class="{
                                    notvalid: firstNameValidation.first_name,
                                    valid: firstNameValidation.first_name === false,
                                }"
                                @input="inputFirstName"
                            />
                        </div>
                        <div class="input-block">
                            <label for="last_name">Фамилия</label>
                            <input
                                type="text"
                                name="last_name"
                                placeholder="Введите фамилию"
                                id="name2"
                                v-model.trim="last_name"
                                :class="{
                                    notvalid: lastNameValidation.last_name,
                                    valid: lastNameValidation.last_name === false,
                                }"
                                @input="inputLastName"
                            />
                        </div>
                        <div class="input-block">
                            <div class="flex">
                                <label for="tel">Телефон</label>
                                <div class="agree">
                                    Указывая телефон, вы принимаете
                                    <a href="#">Политику обработки персональных данных</a>
                                </div>
                            </div>
                            <input
                                type="text"
                                name="tel"
                                id="tel"
                                placeholder="+7 900 000 00 00"
                                v-mask="'+7 ### ### ## ##'"
                                v-model.trim="phone"
                                :class="{
                                    notvalid: phoneValidation.phone,
                                    valid: phoneValidation.phone === false,
                                }"
                                @input="inputPhone"
                            />
                        </div>
                        <div class="input-block">
                            <label for="message">Пожелания к брони</label>
                            <input
                                type="text"
                                name="message"
                                placeholder="Напишите пожелания"
                                id="message"
                                v-model="comment"
                                :class="{
                                    notvalid: commentValidation.comment,
                                    valid: commentValidation.comment === false,
                                }"
                                @input="inputComment"
                            />
                        </div>
                    </div>
                    <div class="inputs" v-if="isCode">
                        <div class="input-block">
                            <input
                                type="text"
                                name="code"
                                placeholder="Введите код"
                                id="code"
                                v-model.trim="verCode"
                                maxlength="4"
                            />
                        </div>
                    </div>
                    <div class="error">{{ errorText }}</div>
                    <div class="form__next">
                        <button
                            v-if="isSelects"
                            type="button"
                            name="continue"
                            :class="notDisableBtn ? `` : `_disable`"
                            :disabled="notDisableBtn ? false : true"
                            @click="continueBook"
                        >
                            Продолжить
                        </button>
                        <button
                            v-if="isInputs"
                            type="button"
                            name="continue2"
                            :class="notDisableBtn2 ? `` : `_disable`"
                            :disabled="notDisableBtn2 ? false : true"
                            @click="validForm"
                        >
                            Продолжить
                        </button>
                        <button
                            v-if="isCode"
                            type="button"
                            name="book"
                            @click="validCode"
                            onclick="ym(88808271, 'reachGoal', 'booking'); return true;"
                            :class="validInputCode ? `` : `_disable`"
                            :disabled="validInputCode ? false : true"
                        >
                            Забронировать
                        </button>
                    </div>
                </form>
                <div class="popupForm__done" v-if="isDone">
                    <div class="popupForm__confirm">
                        <div class="popupForm__srt">
                            {{ showSelectStreet }}
                        </div>
                        <div class="popupForm__srt">{{ currenDate }} в {{ time }}</div>
                        <div class="popupForm__srt">{{ table }}</div>
                    </div>
                    <div class="popupForm__ornament">
                        <img src="@/assets/img/svg/ornament.svg" alt="arrow" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import axios from "axios";
export default {
    name: "ReservationTable",
    components: {
        VueDatePicker,
    },
    data() {
        return {
            isCode: false,
            isSelects: true,
            isInputs: false,
            isDone: false,
            rangeValue: 5,
            rangeTotal: 10,
            date: localStorage.getItem("date"),
            workTimes1: [
                "10:00",
                "11:00",
                "12:00",
                "13:00",
                "14:00",
                "15:00",
                "16:00",
                "17:00",
                "18:00",
                "19:00",
                "20:00",
            ],
            workTimes2: [
                "10:00",
                "11:00",
                "12:00",
                "13:00",
                "14:00",
                "15:00",
                "16:00",
                "17:00",
                "18:00",
                "19:00",
                "20:00",
            ],
            //workTimes2: ["10:00", "15:00", "16:00", "17:00", "18:00", "19:00"],
            //workTimes3: ["15:00", "16:00", "17:00", "18:00", "19:00"],
            time: localStorage.getItem("time"),
            countPersons: ["2 человека", "4 человека", "6 человека", "8 человека", "У нас большая компания"],
            errorText: "",
            table: localStorage.getItem("table"),
            reservationId: "",
            format: (date) => {
                const day = String(date.getDate()).padStart(2, "0");
                const month = String(date.getMonth() + 1).padStart(2, "0");
                const year = date.getFullYear();
                return `${day}.${month}.${year}`;
            },
            // Дни в которые бронь отключена
            disabledDatesTatar: ["2024-07-26"],
            disabledDatesMarjani: [],
            first_name: "",
            last_name: "",
            phone: "",
            comment: "",
            title: "Забронировать столик",
            options: [
                {
                    id: 1,
                    value: "tatar",
                    name: "Кремль, 5",
                    place: "(Казанский кремль)",
                    time: "10:00",
                },
                {
                    id: 2,
                    value: "marjani",
                    name: "Марджани, 4",
                    place: "(Старо-татарская слобода)",
                    time: "10:00",
                },
            ],
            selectShop: this.rest ?? localStorage.getItem("shop"),
            selectStreet: this.street ?? localStorage.getItem("street"),
            selectLocation: this.location ?? localStorage.getItem("location"),
            verCode: "",
            currenDate: "",
            isReserved: false,
            showSelectStreet: "",
            phoneValidation: {
                phone: null,
            },
            firstNameValidation: {
                first_name: null,
            },
            lastNameValidation: {
                last_name: null,
            },
            commentValidation: {
                comment: null,
            },
        };
    },
    props: ["rest", "street", "location", "startTime"],
    methods: {
        continueBook() {
            this.isSelects = false;
            this.isInputs = true;
        },
        backBook() {
            this.isSelects = true;
            this.isInputs = false;
        },
        validForm() {
            if (this.first_name === "") {
                this.errorText = "Заполните поле Имя";
            } else if (this.last_name === "") {
                this.errorText = "Заполните поле фамилия";
            } else if (this.phone === "") {
                this.errorText = "Укажите телефон";
            } else if (this.first_name.length <= 1) {
                this.errorText = "Слишком короткое имя";
            } else if (this.last_name.length <= 1) {
                this.errorText = "Слишком короткая фамилия";
            } else if (this.phone.length <= 15) {
                this.errorText = "Телефон должен состоять из 11 цифр!";
            } else {
                this.isInputs = false;
                this.isCode = true;
                this.title = `Введите код подтверждения\n бронирования.\n Мы отправили его на номер\n ${this.phone}`;
                axios
                    .post(
                        "https://admin.tcode.online/api/projects/tatar/shops/" + this.selectShop + "/reservation-add/",
                        {
                            reserved_time:
                                this.date.split(".")[2] +
                                "-" +
                                this.date.split(".")[1] +
                                "-" +
                                this.date.split(".")[0] +
                                ` ` +
                                this.time,
                            phone: this.phone,
                            table: this.table,
                            first_name: this.first_name,
                            last_name: this.last_name,
                            comment: this.comment,
                        },
                        {
                            headers: {
                                // remove headers
                            },
                        }
                    )
                    .then((response) => {
                        console.log(response);
                        this.reservationId = response.data.id;
                        this.isReserved = response.data.is_reserved;
                    })
                    .catch((error) => {
                        console.log(error.response);
                    });
            }
        },
        validCode() {
            if (this.verCode.length === 4 && this.verCode && this.isReserved === true) {
                axios
                    .post(
                        "https://admin.tcode.online/api/projects/tatar/shops/" +
                            this.selectShop +
                            "/reservation-confirm/",
                        {
                            reservation_id: this.reservationId,
                            verification_code: this.verCode,
                        },
                        {
                            headers: {
                                // remove headers
                            },
                        }
                    )
                    .then((response) => {
                        console.log(response);
                    })
                    .catch((error) => {
                        console.log(error.response);
                    });
                this.currenDate = this.date;
                this.phone.length > 15 ? this.phone.length === 15 : 0;
                this.errorText = "";
                this.first_name = "";
                this.last_name = "";
                this.phone = "";
                this.comment = "";
                this.title = `Ваше бронирование оформлено,\nожидайте ответное смс-оповещение с принятием или отказом брони`;
                this.isInputs = false;
                this.isDone = true;
                this.showSelectStreet = this.selectStreet;
                this.selectShop = null;
                this.date = "";
                this.selectStreet = "Выберите ресторан";
                this.selectLocation = "";
                this.isCode = false;
            }
        },
        inputPhone() {
            this.phoneValidation.phone = !/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){11,16}(\s*)?$/.test(this.phone)
                ? true
                : false;
        },
        inputFirstName() {
            this.firstNameValidation.first_name = !/^[а-яА-ЯёЁA-Za-z]+$/.test(this.first_name) ? true : false;
        },
        inputLastName() {
            this.lastNameValidation.last_name = !/^[а-яА-ЯёЁA-Za-z]+$/.test(this.last_name) ? true : false;
        },
        inputComment() {
            this.commentValidation.comment = !/^[- _():;,.0-9а-яА-ЯёЁA-Za-z]+$/.test(this.comment) ? true : false;
        },
    },
    computed: {
        reloadPage() {
            return (this.selectShop && this.date) || this.isDone
                ? window.location.reload(window.location.replace(`/#${localStorage.getItem("block")}`))
                : false;
        },
        notDisableBtn() {
            return this.selectShop && this.date;
        },
        notDisableBtn2() {
            return this.first_name && this.last_name && this.phone && this.phone.length === 16;
        },
        selectWorkTimes() {
            return this.selectShop === "marjani" ? this.workTimes2 : this.workTimes1;
        },
        validInputCode() {
            return this.verCode.length === 4 && this.verCode && this.isReserved === true;
        },
    },
    watch: {
        selectShop: function () {
            localStorage.setItem("shop", this.selectShop);
            localStorage.setItem("street", this.selectStreet);
            localStorage.setItem("location", this.selectLocation);
        },
        date: function () {
            localStorage.setItem("date", this.date);
        },
        time: function () {
            localStorage.setItem("time", this.time);
        },
        table: function () {
            localStorage.setItem("table", this.table);
        },
    },
    mounted() {
        axios.get(`https://admin.tcode.online/api/projects/tatar/shops/tatar/block-dates/`).then((response) => {
            console.log(response.data);
            let dates = response.data;
            for (let i = 0; i < dates.length; i++) {
                this.disabledDatesTatar.push(dates[i].date);
            }
        });
        axios
            .get(`https://admin.tcode.online/api/projects/tatar/shops/marjani/block-dates/`)
            .then((response) => {
                console.log(response.data);
                let dates = response.data;
                for (let j = 0; j < dates.length; j++) {
                    this.disabledDatesMarjani.push(dates[j].date);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        if (this.selectShop === null) {
            this.selectShop = "";
            this.selectStreet = "Выберите ресторан";
            this.selectLocation = "";
            this.time = "10:00";
        } else if (this.selectShop === "marjani") {
            this.time = "10:00";
        } else if (this.selectShop === "tatar") {
            this.time = "10:00";
        }

        if (this.table === null) {
            this.table = "2 человека";
        }

        let listRest = document.querySelector(".listRest");
        let arrow = document.querySelector(".arrow");
        let nameRests = document.querySelectorAll(".nameRest");
        let selectedRest = document.getElementById("selectedRest");
        let currentRest = selectedRest.querySelector(".currentRest");
        selectedRest.addEventListener("click", function () {
            listRest.classList.toggle("_showSelect");
            arrow.classList.toggle("_rotate");
        });
        for (let nameRest of nameRests) {
            nameRest.addEventListener("click", function () {
                currentRest.innerHTML = this.innerHTML;
                currentRest.style.color = "#2a374b";
            });
        }
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/popup-form.scss";
</style>
